
import { Component, Vue } from 'vue-property-decorator'
import client from '@/services/customerAccount'
import dayjs from 'dayjs'
import {
  CustomerAccountDetailEntity,
  CustomerAccountRequest,
} from '@/models/dto/CustomerAccount'
import DetailsContent from '@/components/DetailsContent.vue'
import DetailWithSidebar from '@/layouts/DetailWithSidebar.vue'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import CompanyGroupSelector from '@/components/CompanyGroupSelector.vue'
import CompanySidebarDetail from '@/components/CompanySidebarDetail.vue'
import { Note } from '@/models/dto/Note'
import { EventBus } from '@/utils/eventBus'
import { NotificationsSetting } from '@/models/NotificationsSetting'

@Component({
  components: {
    DetailsContent,
    DetailWithSidebar,
    AutocompleteAddress,
    CompanyGroupSelector,
    CompanySidebarDetail,
  },
  metaInfo: {
    title: 'Company Details',
  },
})
export default class CompanyDetails extends Vue {
  customerAccount: CustomerAccountDetailEntity = null
  customerAccountId: number = null
  loading = false
  companyHasBeenModified = false

  get companyNote(): Note {
    return this.customerAccount?.customerAccountNotes?.[0]
  }

  async handleCreateNote(note: Note): Promise<void> {
    this.customerAccount.customerAccountNotes = [note]
    const customerAccountRequest = new CustomerAccountRequest(
      this.customerAccount
    )
    customerAccountRequest.customers = null
    await client.update(this.customerAccountId, customerAccountRequest)
    this.load()
  }

  async handleSaveCompany(): Promise<void> {
    this.loading = true
    const ref: any = this.$refs['company-sidebar-detail']

    await ref.submit()
    this.loading = false
    this.companyHasBeenModified = false
  }

  async handleUpdateSendQuotesAsInvoice(
    sendQuotesAsInvoice: boolean
  ): Promise<void> {
    this.customerAccount.sendQuotesAsInvoice = sendQuotesAsInvoice
    const customerAccountRequest = new CustomerAccountRequest(
      this.customerAccount
    )
    customerAccountRequest.address = null
    await client.update(this.customerAccountId, customerAccountRequest)
    this.load()
  }

  async handleNotificationsUpdate({
    defaultSendDriverOnTheWayEmail,
    defaultSendDriverInfoEmail,
  }: NotificationsSetting): Promise<void> {
    try {
      this.customerAccount.notificationsSetting = {
        defaultSendDriverOnTheWayEmail,
        defaultSendDriverInfoEmail,
      }
      await client.updateNotificationsSetting(this.customerAccountId, {
        defaultSendDriverOnTheWayEmail,
        defaultSendDriverInfoEmail,
      })
    } catch (e) {
      console.error(e)
      EventBus.$emit('notification:error', {
        message: 'An error occurred while updating notifications',
      })
    }
  }

  async load(): Promise<void> {
    this.customerAccountId = this.$route.params.id
      ? Number.parseInt(this.$route.params.id, 10)
      : null
    if (this.customerAccountId) {
      await client
        .byId(this.customerAccountId)
        .then((res) => res.data)
        .then((customerAccount) => (this.customerAccount = customerAccount))
    }
  }

  async mounted(): Promise<void> {
    EventBus.$on('note:create', this.handleCreateNote)
    EventBus.$on(
      'update:send-quotes-as-invoice',
      this.handleUpdateSendQuotesAsInvoice
    )
    EventBus.$on('update:notifications', this.handleNotificationsUpdate)

    await this.load()
    this.companyHasBeenModified = false
  }

  beforeDestroy(): void {
    EventBus.$off('note:create', this.handleCreateNote)
    EventBus.$off(
      'update:send-quotes-as-invoice',
      this.handleUpdateSendQuotesAsInvoice
    )
    EventBus.$off('update:notifications', this.handleNotificationsUpdate)
  }
}
